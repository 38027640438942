import About from './Components/About';
import Footer from './Components/Footer';
import Franchise from './Components/Franchise';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TermsAndConditions from "./Components/TermsAndConditions";
import ReturnandRefund from "./Components/ReturnandRefund";
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/About' element={<About />} />
        <Route path='/Franchise' element={<Franchise />} />
        <Route path="/TermsService" element={<TermsAndConditions />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/ReturnandRefund" element={<ReturnandRefund />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
