import React, { useState } from "react";
import WhyChooseUsImg from "../images/WhyChooseUsImg.png";
import f1 from '../images/f1.png'
import f2 from '../images/f2.png'
import f3 from '../images/f3.png'
import f10 from '../images/f10.png'

import FranchiseImg from '../images/HeroImg3.png'
import '../Css/bootstrap.css'
import '../Css/style.css'
const Home = () => {


  const [selectedCategory,setSelectedCategory]=useState('all');

  const handleFilterClick = (category) => {
    setSelectedCategory(category);
  };

  const menuItems = [
    {
      category: "pizza",
      Src: f1,
      title: "Delicious Pizza",
      description:
        "Experience the mouthwatering sensation of indulging in the irresistible combination of melted cheese, crispy crust, and flavorful fresh toppings in every delectable bite.",
      price: "INR 999",
    },
    {
      category: "burger",
      Src: f2,
      title: "Delicious Burger",
      description:
        "Enjoy juicy burgers with your favorite toppings and sauces, made with simple ingredients.",
      price: "INR 350",
    },
    {
      category: "dessert",
      Src: f10,
      title: "Sweet Dessert",
      description:
        "Satisfy your sweet cravings with our delightful Sweet Dessert. Indulge in the harmonious blend of rich flavors and tempting textures. it's the perfect conclusion to your dining experience..",
      price: "INR 200",
    },
  ];

  return (
    <>

      <section className="food_section layout_padding-bottom">
        <div className="container">
          <div className="heading_container heading_center">
            <h2>Our Menu</h2>
          </div>

          <ul className="filters_menu">
            <li
              className={selectedCategory === 'all' ? 'active' : ''}
              onClick={() => handleFilterClick('all')}
              data-filter="*"
            >
              All
            </li>
            {menuItems.map((item) => (
              <li
                key={item.category}
                className={selectedCategory === item.category ? 'active' : ''}
                onClick={() => handleFilterClick(item.category)}
                data-filter={`.${item.category}`}
              >
                {item.category}
              </li>
            ))}
          </ul>

          <div className="filters-content">
            <div className="row grid">
              {menuItems
                .filter((item) => selectedCategory === 'all' || item.category === selectedCategory)
                .map((item) => (
                  <div
                    key={item.category}
                    className={`col-sm-6 col-lg-4 all ${item.category}`}
                  >
                    <div className="box" style={{ height: '500px' }}>
                      <div>
                        <div className="img-box">
                          <img src={item.Src} alt="hi" />
                        </div>
                        <div className="detail-box">
                          <h5>{item.title}</h5>
                          <h6>{item.price}</h6>
                          <p>{item.description}</p>
                          <div className="options">
                            {/* Additional details or options */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>

      <section className="about_section layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="img-box">
                <img src={WhyChooseUsImg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="detail-box">
                <div className="heading_container">
                  <h2>Why Choose Us</h2>
                </div>
                <p>
                  The Robot Restaurants are the leaders in using advanced
                  robotics to create dishes that challenge and delight the
                  senses, while ensuring a smooth and enjoyable service. You can
                  also customize your menu and enjoy interactive shows, making
                  your dining adventure truly unique and adaptable. Whether it’s
                  a birthday, an anniversary, or a special occasion, The Robot
                  Restaurants will make it remarkable with their robotic
                  gastronomy services.
                </p>
                {/* <a href="">Read More</a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="book_section layout_padding">
        <div className="container">
          <div className="heading_container">
            <h2>Request for Frenchise</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form_container">
                <form action="">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                    />
                  </div>
                  <div>
                    <select className="form-control nice-select wide">
                      <option value="" disabled selected>
                        How many persons?
                      </option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                  <div>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="btn_box">
                    <button>Submit</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div><img src={FranchiseImg} style={{marginTop:'-100px'}}></img></div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Home;
